import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { validate } from 'wallet-address-validator';

import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import AppLoader from "../../components/AppLoader";
import { Token, TokenAddress } from '../../store/contract/index';
import { setPageLoader, togglePurposeOwner, togglePauseContract, getFreezeHistory, freezeAddress, setProtector, unFreeze } from '../../store/actions/Auth.js';

class SilverProtector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      userAddress: '',
      isLoader: false,

      protector: '',
      isProtector: false,
      proposeProtector: '',
      modalProtector: false,

      isFreeze: false,
      modalFreeze: false,
      proposeFreeze: '',
      frozenHistoryLocal: [],

      paused: true,
      isPaused: true,
    };
    props.getFreezeHistory();
  };

  componentDidMount() {
    this.checkContractState();
  }

  async componentWillReceiveProps({ frozenHistory }) {
    this.checkContractState();
    let frozenHistoryLocal = [];
    for (let frozen of frozenHistory) {
      frozen['balance'] = await Token.methods.balanceOf(frozen['publicAddress']).call();
      frozen['balance'] = web3.utils.fromWei(frozen['balance'].toString(), 'ether');
      frozenHistoryLocal.push(frozen);
    }
    this.setState({ frozenHistoryLocal });
  };

  checkContractState = async () => {
    let userAddress = (await web3.currentProvider.enable())[0];
    let protector = await Token.methods.assetProtectionRole().call();
    this.setState({ userAddress, protector })
  }

  setModalProtector = (modalProtector) => this.setState({ modalProtector });
  setProtector = async () => {
    try {
      let { proposeProtector, protector } = this.state;

      if (proposeProtector == '') {
        EventBus.publish('error', `Please Enter the Address`);
        return;
      };

      if (proposeProtector == protector) {
        EventBus.publish('info', `Address has been already added`);
        return;
      };

      if (!validate(proposeProtector, 'ETH')) {
        EventBus.publish('error', `Please provide valid ETH Address`);
        return;
      };
      this.props.setPageLoader(true);
      this.setState({ isProtector: true });
      let from = (await web3.currentProvider.enable())[0];

      /** Add Proposed Owner **/
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.setAssetProtectionRole(proposeProtector).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', receipt => {
          let data = { transactionHash: receipt['transactionHash'] };
          this.props.setPageLoader(false);
          this.setState({ isProtector: false, protector: proposeProtector, proposeProtector: '', modalProtector: false });
          this.props.setProtector(data);
          // EventBus.publish('success', `Silver protector changed successfully`);
        }).on('error', e => {
          this.props.setPageLoader(false);
          this.setState({ isProtector: false });
          EventBus.publish('error', `Unable to Set Protector, Please View details on Explorer`);
        });
    } catch (e) {
      this.props.setPageLoader(false);
      this.setState({ isProtector: false });
      EventBus.publish('error', `Unable to Set Protector, Please View details on Explorer`);
    }
  };

  setModalFreeze = (modalFreeze) => this.setState({ modalFreeze });
  setFreeze = async () => {
    try {
      let { proposeFreeze } = this.state;
      if (proposeFreeze == '') {
        EventBus.publish('error', `Please Enter the Address`);
        return;
      };

      if (!validate(proposeFreeze, 'ETH')) {
        EventBus.publish('error', `Please provide valid ETH Address`);
        return;
      }
      if (await Token.methods.isFrozen(proposeFreeze).call()) {
        this.setState({ proposeFreeze: '' });
        EventBus.publish('error', `This user is already Frozen`);
        return;
      };
      this.props.setPageLoader(true);
      this.setState({ isFreeze: true });
      let from = (await web3.currentProvider.enable())[0];

      /** Add Proposed Owner **/
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.freeze(proposeFreeze).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', receipt => {
          let data = { transactionHash: receipt['transactionHash'] };
          this.props.freezeAddress(data);
          this.props.setPageLoader(false);
          this.setState({ isFreeze: false, modalFreeze: false, proposeFreeze: '' });
        }).on('error', e => {
          this.props.setPageLoader(false);
          this.setState({ isFreeze: false });
          EventBus.publish('error', `Unable to Freeze Address, Please View details on Explorer`);
        });
    } catch (e) {
      this.props.setPageLoader(false);
      this.setState({ isFreeze: false });
      EventBus.publish('error', `Unable to Freeze Address, Please View details on Explorer`);
    }
  };

  unFreeze = async (address) => {
    try {
      let from = (await web3.currentProvider.enable())[0];
      this.props.setPageLoader(true);
      // this.setState({ isLoader: true });

      /** UnFreeze **/
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.unfreeze(address).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', receipt => {
          let data = { transactionHash: receipt['transactionHash'] };
          this.props.setPageLoader(false);
          // this.setState({ isLoader: false });
          this.props.unFreeze(data);
        }).on('error', e => {
          this.props.setPageLoader(false);
          // this.setState({ isLoader: false });
          EventBus.publish('error', `Unable to UnFreeze User, Please View details on Explorer`);
        });
    } catch (e) {
      this.props.setPageLoader(false);
      // this.setState({ isLoader: false });
      EventBus.publish('error', `Unable to UnFreeze User, Please View details on Explorer`);
    }
  }

  wipeAddress = async (address) => {
    try {
      let from = (await web3.currentProvider.enable())[0];
      this.props.setPageLoader(true);
      // this.setState({ isLoader: true });

      /** Wipe All Balance **/
      web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.wipeFrozenAddress(address).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', receipt => {
          this.props.getFreezeHistory();
          this.props.setPageLoader(false);
          // this.setState({ isLoader: false });
          EventBus.publish('success', `Wiped user balance succesfully`);
        }).on('error', e => {
          this.props.setPageLoader(false);
          // this.setState({ isLoader: false });
          EventBus.publish('error', `Unable to Wipe Balance, Please View details on Explorer`);
        });
    } catch (e) {
      this.props.setPageLoader(false);
      // this.setState({ isLoader: false });
      EventBus.publish('error', `Unable to Wipe Balance, Please View details on Explorer`);
    }
  }

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    let { userAddress, frozenHistoryLocal, isFreeze, proposeFreeze, modalFreeze } = this.state;
    let { protector, modalProtector, proposeProtector, isProtector, isLoader } = this.state;

    const columns = [
      {
        id: 'date',
        Header: 'Date',
        accessor: frozenHistory => frozenHistory['createdAt'] ? moment(frozenHistory["createdAt"]).format("lll") : '-',
      }, {
        id: 'publicAddress',
        Header: 'Frozen Address',
        accessor: frozenHistory => frozenHistory['publicAddress']
          ? <a href={`${explorer}/address/${frozenHistory['publicAddress']}`} target='_blank' style={{ color: '#4c4c4c' }}>
            View on Explorer
          </a>
          : '-',
      }, {
        id: 'balance',
        Header: 'User Balance',
        accessor: frozenHistory => frozenHistory['balance'] + ' TEX'
      }, {
        id: 'unfreeze',
        Header: 'Un Freeze',
        accessor: frozenHistory => <Fragment>
          {protector && userAddress.toLowerCase() == protector.toLowerCase() &&
            <Button variant="outlined" className="mx-1 view-btn" onClick={() => this.unFreeze(frozenHistory['publicAddress'])}><i className='fa fa-unlock' /></Button>
          }
        </Fragment>,
        filterable: false,
      }, {
        id: 'wipeBalance',
        Header: 'Wipe User Balance',
        accessor: frozenHistory => <Fragment>
          {protector && userAddress.toLowerCase() == protector.toLowerCase() &&
            <Button variant="outlined" className="mx-1 view-btn" onClick={() => this.wipeAddress(frozenHistory['publicAddress'])}><i className='fa fa-exclamation-triangle' /></Button>
          }
        </Fragment>,
        filterable: false,
      }
    ];

    return (
      <div className='content protector'>
        <div className="main-container">
          <Fragment>
            <div className='main-container-head mb-3'>
              <p className="main-container-heading">Protector Address:
                <span className='protector-address'> {protector} </span>
              </p>
              <div>
                <Button variant="outlined" className="add-btn mr-3" onClick={() => this.setModalProtector(true)}>Set Protector</Button>
                {protector && userAddress.toLowerCase() == protector.toLowerCase() &&
                  <Button variant="outlined" className="add-btn" onClick={() => this.setModalFreeze(true)}>Freeze User</Button>
                }
              </div>
            </div>
            {isLoader
              ? <AppLoader message="Please Waiting..." />
              : <ReactTable
                className="table"
                data={frozenHistoryLocal}
                resolveData={frozenHistoryLocal => frozenHistoryLocal.map(row => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            }
          </Fragment>
          {/* } */}
        </div>

        {/* ---------------PURPOSED PROTECTOR MODAL--------------- */}
        <Modal isOpen={modalProtector} toggle={() => this.setModalProtector(false)} className="main-modal purpose-modal">
          <ModalHeader toggle={() => this.setModalProtector(false)}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="burn-modal-title text-center"><p className='text-white' style={{ fontSize: '18px', fontWeight: '600' }}>New Protector</p></div>
            <div className="burn-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.setProtector}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='proposeProtector'
                    value={proposeProtector}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Address of New Protector <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isProtector
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------PROPOSED FREEZE MODAL--------------- */}
        <Modal isOpen={modalFreeze} toggle={() => this.setModalFreeze(false)} className="main-modal purpose-modal">
          <ModalHeader toggle={() => this.setModalFreeze(false)}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="burn-modal-title text-center"><p className='text-white' style={{ fontSize: '18px', fontWeight: '600' }}>Freeze User</p></div>
            <div className="burn-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.setFreeze}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='proposeFreeze'
                    value={proposeFreeze}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Freeze account address <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isFreeze
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

const mapDispatchToProps = {
  setPageLoader, togglePurposeOwner, togglePauseContract, getFreezeHistory, freezeAddress, setProtector, unFreeze
};

const mapStateToProps = ({ Auth }) => {
  let { isPurposeModal, isPauseModal, frozenHistory } = Auth;
  return { isPurposeModal, isPauseModal, frozenHistory };
};
export default connect(mapStateToProps, mapDispatchToProps)(SilverProtector);