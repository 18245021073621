/* -- set app title --*/
const AppTitle = 'TEX Token';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const production = 'https://dtexserver.crowdpoint.tech/api';
const staging = 'https://13.58.219.241:4000/api';
const development = 'https://dtexserver.crowdpoint.tech/api';

/* -- set KEEP Server URLs --*/

const productionServer = 'https://pkeepserver.theellipsis.exchange/api';
const stagingServer = 'https://13.58.219.241:4000/api';
const developmentServer = 'https://dserver.crowdpoint.tech/api';

let env = AppMode[0] || 'development', ApiUrl = '', ServerURL = '', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 97;
    message = 'Please switch your network to BSC testnet';
    ApiUrl = development;
    ServerURL = developmentServer;
    explorer = 'https://testnet.bscscan.com'
    break;

  case 'production':
    networkId = 56;
    ApiUrl = production;
    ServerURL = productionServer;
    message = 'Please switch your network to BSC Mainnet';
    explorer = 'https://bscscan.com'
    break;

  case 'staging':
    networkId = 4;
    ApiUrl = staging;
    ServerURL = stagingServer;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    break;

  default:
    networkId = 97;
    message = 'Please switch your network to BSC testnet';
    ApiUrl = 'http://192.168.18.19:4000/api';
    ServerURL = 'http://192.168.18.11:4000/api';
    explorer = 'https://testnet.bscscan.com'
}

export { AppTitle, ApiUrl, ServerURL, networkId, message, explorer, env };
