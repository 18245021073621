import { PURGE } from "redux-persist";
import { setToken } from '../axios';

var initialState =
{
  isPageLoader: false,

  nonce: '',
  isLogin: false,
  isClaim: false,
  auth: localStorage.getItem('token'),
  role: localStorage.getItem('role'),
  balance: '0',
  address: '',

  tokenDetails: {},

  ownerHistory: [],
  frozenHistory: [],
  isPurposeModal: false,
  isPauseModal: false,

  isSupplierModal: false,
  supplierHistory: [],

  isMintingModal: false,
  isHistorySlider: false,
  isBurnModal: false,

  setFeeModal: false,
  setControllerModal: false,
  setRecipientModal: false,
};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE: return initialState;

    case 'SAVE_LOGIN_DATA':
      setToken(payload['token']);
      localStorage.setItem('token', payload['token']);
      localStorage.setItem('role', payload['role']);
      return {
        ...state,
        auth: payload['token'],
        role: payload['role'],
      };

    case 'IS_LOGIN_DISABLED':
      return {
        ...state,
        isLogin: !state.isLogin,
      };

    case 'IS_CLAIM_DISABLED':
      return {
        ...state,
        isClaim: !state.isClaim,
      };

    case 'LOGOUT':
      setToken();
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      return {
        ...state,
        auth: '',
        role: '',
      };

    case 'SET_ADDRESS':
      return {
        ...state,
        address: payload
      };

    case 'SET_BALANCE':
      return {
        ...state,
        balance: payload
      };

    /*========== PAGE LOADER REDUCERS ============= */

    case 'SET_PAGE_LOADER':
      return {
        ...state,
        isPageLoader: payload
      };

    /*========== NONCE REDUCERS ============= */

    case 'SET_NONCE':
      return {
        ...state,
        nonce: payload
      };

    /*========== OWNER REDUCERS ============= */

    case 'SET_OWNER_HISTORY':
      return {
        ...state,
        ownerHistory: payload
      }

    case 'TOGGLE_PURPOSE_OWNER':
      return {
        ...state,
        isPurposeModal: !state.isPurposeModal,
      };

    case 'TOGGLE_PAUSE_CONTRACT':
      return {
        ...state,
        isPauseModal: !state.isPauseModal,
      };

    /*========== SUPPLIER REDUCERS ============= */

    case 'SET_SUPPLIER_HISTORY':
      return {
        ...state,
        supplierHistory: payload
      };

    case 'TOGGLE_HISTORY_SLIDER':
      return {
        ...state,
        isHistorySlider: !state.isHistorySlider,
      };

    case 'TOGGLE_SUPPLIER_MODAL':
      return {
        ...state,
        isSupplierModal: !state.isSupplierModal,
      };

    /*========== PROTECTOR REDUCERS ============= */

    case 'SET_FREEZE_HISTORY':
      return {
        ...state,
        frozenHistory: payload
      };

    /*========== MINTING REDUCERS ============= */

    case 'TOGGLE_MINTING_MODAL':
      return {
        ...state,
        isMintingModal: payload,
      };

    /*========== BURN REDUCERS ============= */

    case 'TOGGLE_BURN_MODAL':
      return {
        ...state,
        isBurnModal: !state.isBurnModal,
      };

    /*========== FEE CONTROLLER REDUCERS ============= */

    case 'TOGGLE_SET_FEE':
      return {
        ...state,
        setFeeModal: !state.setFeeModal,
      };

    case 'TOGGLE_FEE_CONTROLLER':
      return {
        ...state,
        setControllerModal: !state.setControllerModal,
      };

    case 'TOGGLE_FEE_RECIPIENT':
      return {
        ...state,
        setRecipientModal: !state.setRecipientModal,
      };

    default:
      return state;
  }
};

export default Auth;