import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import './index.css';
import { web3 } from '../../store/web3';
import AppLoader from '../../components/AppLoader';
import { Token, TokenAddress } from '../../store/contract/index';

class TokenDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      symbol: '',
      feeController: '',
      totalSupply: '',
      decimals: '',
      tokenAddress: '',
    };
  };

  async componentDidMount() {
    this.setState({ tokenAddress: TokenAddress });
    let name = await Token.methods.name().call();
    let symbol = await Token.methods.symbol().call();
    let decimals = await Token.methods.decimals().call();
    let supply = await Token.methods.totalSupply().call();
    let totalSupply = await web3.utils.fromWei(supply.toString(), 'ether');

    // CHECK OWNER
    let owner = await Token.methods.owner().call();

    // CHECK PROPOSED OWNER
    let proposedOwner = await Token.methods.proposedOwner().call();

    // CHECK SUPPLIER OWNER
    let supplierAddress = await Token.methods.supplyController().call();

    // Asset Protector
    let silverProtector = await Token.methods.assetProtectionRole().call();

    // CHECK FEE CONTROLLER 
    let feeParts = await Token.methods.feeParts().call();
    let feeRate = await Token.methods.feeRate().call();
    let feePercent = feeRate / feeParts * 100;
    let feeRecipient = await Token.methods.feeRecipient().call();
    let feeControllerAddress = await Token.methods.feeController().call();
    this.setState({ feePercent, name, symbol, totalSupply, decimals, owner, proposedOwner, supplierAddress, silverProtector, feeControllerAddress, feeRecipient });
  };

  render() {
    let { tokenAddress, feePercent, name, symbol, totalSupply, decimals, owner, proposedOwner, supplierAddress, silverProtector, feeControllerAddress, feeRecipient } = this.state;
    return (
      <div className='content'>
        <div className="main-container pb-5">
          <div className='main-container-head mb-3'>
            <p className="main-container-heading">Token Details</p>
          </div>
          <hr />
          <div className='row d-flex justify-content-center'>
            {name == '' || symbol == ''
              ? <AppLoader />
              : <div className='edit-add pb-5 mt-3 offset-md-2 col-md-8'>
                <div className="view-data row">
                  <div className="view-data-main-title col-12">
                    <p className="text-dark text-left ml-2 pl-5">Details of TEX Token</p>
                  </div>
                  <div className="view-data-body col-12">
                    <div className="view-data-row my-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Token Name:</span> {name ? name : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Token Symbol:</span> {symbol ? symbol : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Token Address:</span> <span className='text-address'>{tokenAddress ? tokenAddress : '-'}</span> </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Circulating Supply:</span> {totalSupply ? totalSupply + ` ${symbol}` : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">No. of Decimals:</span> {decimals ? decimals : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Owner Address:</span> <span className='text-address'>{owner ? owner : '-'}</span> </p>
                    </div>
                    {proposedOwner != '0x0000000000000000000000000000000000000000' &&
                      <div className="view-data-row mt-2 ml-5 col-md-8">
                        <p className="text-dark text-left pl-2"><span className="view-data-title">Proposed Owner:</span> <span className='text-address'>{proposedOwner}</span> </p>
                      </div>
                    }
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Supply Manager:</span> <span className='text-address'>{supplierAddress ? supplierAddress : '-'}</span> </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Silver Protector:</span> <span className='text-address'>{silverProtector ? silverProtector : '-'}</span> </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Fee Percent:</span> {feePercent ? feePercent + '%' : '-'} </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Fee Manager:</span> <span className='text-address'>{feeControllerAddress ? feeControllerAddress : '-'}</span> </p>
                    </div>
                    <div className="view-data-row mt-2 ml-5 col-md-8">
                      <p className="text-dark text-left pl-2"><span className="view-data-title">Fee Receiver:</span> <span className='text-address'>{feeRecipient ? feeRecipient : '-'}</span> </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TokenDetails);