import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import Validator from 'wallet-address-validator';
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import Slider from './slider';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import AppLoader from "../../components/AppLoader";
import { Token, TokenAddress } from '../../store/contract/index';
import { ServerURL } from '../../store/config';
import {
  setPageLoader, getSupplierHistory, toggleSupplierModal, addSupplier, setAddress, setBalance,
  mintTokens, toggleMintingModal, burnTokens, toggleBurnModal, toggleHistorySlider,
} from '../../store/actions/Auth.js';

const { REACT_APP_PRIVATE_KEY } = process['env'];

class SupplyController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingTEX: [],
      supplierAddress: '',
      address: '',
      userAddress: '',
      isSupplier: false,
      burnAmount: '',
      isBurning: false,
      mintAddress: '',
      mintAmount: '',
      isMinting: false,
      isApprovedModal: false,
      isApproved: true,
      approveID: '',
      approveAmount: '',
    };
    props.getSupplierHistory();
  };

  async componentDidMount() {
    this.getPendingTEX();
    let ownerAddress = await Token.methods.owner().call();
    let userAddress = (await web3.currentProvider.enable())[0];
    let supplierAddress = await Token.methods.supplyController().call();
    this.setState({ supplierAddress, userAddress, ownerAddress })
  };

  async componentWillReceiveProps({ isMintingModal, isBurnModal, isSupplierModal }) {
    let supplierAddress = await Token.methods.supplyController().call();
    this.setState({ supplierAddress });

    if (!isBurnModal) this.setState({ burnAmount: '' });
    if (!isMintingModal) this.setState({ mintAddress: '', mintAmount: '' });
    if (!isSupplierModal) this.setState({ address: '' });
  };

  getPendingTEX = () => {
    fetch(`${ServerURL}/buySilver/texPending/${REACT_APP_PRIVATE_KEY}`)
      .then(response => response.json())
      .then(response => {
        this.setState({ pendingTEX: response['body']['pendingTex'], approveAddress: response['body']['address'] });
      }).catch(error => {
        console.log('*******error:::', error);
        EventBus.publish('error', "Pending TEX request not found!");
      });

  }

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  showApproveModal = (approveAmount, approveID) => {
    this.setState({ isApprovedModal: true });
    this.setState({ approveAmount, approveID });
  };

  hideApproveModal = () => {
    this.setState({ isApprovedModal: false });
    this.setState({ approveAmount: '', approveID: '' });
  };

  sendApproveTEX = (data) => {
    fetch(`${ServerURL}/buySilver/minted`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(response => {
        this.getPendingTEX();
        console.log('*******response:::', response);
      }).catch(error => console.log('*******error:::', error));
  }

  handleApprove = async () => {
    let { approveAddress, approveAmount, approveID } = this.state;

    let validAddress = Validator.validate(approveAddress, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    };

    if (approveAmount <= 0) {
      EventBus.publish('error', 'Amount must be greater than zero');
      return
    };

    this.props.setPageLoader(true);
    this.setState({ isApproved: false });
    let from = (await web3.currentProvider.enable())[0];
    approveAmount = web3.utils.toWei(approveAmount.toString(), 'ether');

    /** Mint Tokens */
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.increaseSupply(approveAddress, approveAmount, approveID).encodeABI(),
    })
      .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.mintTokens(data);
        let approveData = { transactionHash: receipt['transactionHash'], key: REACT_APP_PRIVATE_KEY }
        this.updateBalance();
        this.sendApproveTEX(approveData);
        this.props.setPageLoader(false);
        this.setState({ isApproved: true, isApprovedModal: false });
      }).on('error', e => {
        this.props.setPageLoader(false);
        this.setState({ isApproved: true });
        EventBus.publish('error', `Unable to mint Token, View details on Explorer`);
      });
  };

  handleAddSupplier = async () => {
    let { address } = this.state;
    let supplyController = await Token.methods.supplyController().call();

    if (address == '') {
      EventBus.publish('error', 'Please Enter the Address');
      return;
    };

    if (address == supplyController) {
      EventBus.publish('info', 'Supplier address has been already added');
      return;
    };

    let validAddress = Validator.validate(address, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    };

    this.props.setPageLoader(true);
    this.setState({ isSupplier: true });
    let from = (await web3.currentProvider.enable())[0];

    /** Change Supplier **/
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.setSupplyController(address).encodeABI(),
    }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.addSupplier(data);
        this.props.setPageLoader(false);
        this.setState({ isSupplier: false, supplierAddress: address });
      }).on('error', e => {
        this.props.setPageLoader(false);
        this.setState({ isSupplier: false });
        EventBus.publish('error', `Unable to change Supplier, Please View details on Explorer`);
      });
  };

  handleMintTokens = async () => {
    let { mintAddress, mintAmount } = this.state;

    let validAddress = Validator.validate(mintAddress, 'ETH');
    if (!validAddress) {
      EventBus.publish('error', 'Please provide valid address!');
      return
    };

    if (mintAmount <= 0) {
      EventBus.publish('error', 'Amount must be greater than zero');
      return
    };

    this.props.setPageLoader(true);
    this.setState({ isMinting: true });
    let from = (await web3.currentProvider.enable())[0];
    mintAmount = web3.utils.toWei(mintAmount.toString(), 'ether');

    /** Mint Tokens */
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.increaseSupply(mintAddress, mintAmount).encodeABI(),
    })
      .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.mintTokens(data);
        this.updateBalance();
        this.props.setPageLoader(false);
        this.setState({ isMinting: false });
      }).on('error', e => {
        this.props.setPageLoader(false);
        this.setState({ isMinting: false });
        EventBus.publish('error', `Unable to mint Token, View details on Explorer`);
      });
  };

  burnTokensFunc = async () => {
    let { burnAmount } = this.state;
    if (burnAmount <= 0) {
      EventBus.publish('error', 'Amount must be greater than zero');
      return;
    };

    this.setState({ isBurning: true });
    let from = (await web3.currentProvider.enable())[0];
    burnAmount = web3.utils.toWei(burnAmount.toString(), 'ether');

    let balance = await Token.methods.balanceOf(from).call();
    if (Number(balance) < Number(burnAmount)) {
      balance = web3.utils.fromWei(balance.toString(), 'ether');
      this.setState({ isBurning: false });
      EventBus.publish('error', `Burn amount is greater than your balance, which is ${balance}`);
      return;
    };

    this.props.setPageLoader(true);

    /** Send Transaction and Buy Token */
    web3.eth.sendTransaction({
      from,
      value: 0,
      to: TokenAddress,
      data: Token.methods.decreaseSupply(burnAmount).encodeABI(),
    })
      .on('transactionHash', (hash) => console.log(`*******hash = `, hash))
      .on('receipt', receipt => {
        let data = { transactionHash: receipt['transactionHash'] };
        this.props.burnTokens(data);
        this.updateBalance();
        this.props.setPageLoader(false);
        this.setState({ isBurning: false });
      }).on('error', e => {
        this.props.setPageLoader(false);
        this.setState({ isBurning: false });
        EventBus.publish('error', `Unable to Burn Token, Please View details on Explorer`);
      });
  };

  updateBalance = async () => {
    // SET ADDRESS AND BALANCE
    let address = (await web3.currentProvider.enable())[0];
    this.props.setAddress(address)
    let balanceOf = await Token.methods.balanceOf(address).call();
    let balance = web3.utils.fromWei(balanceOf.toString(), 'ether')
    this.props.setBalance(balance);
  };


  render() {
    let { pendingTEX, isApprovedModal, isApproved, ownerAddress, userAddress, supplierAddress, isSupplier, address, isMinting,
      mintAddress, mintAmount, isBurning, burnAmount, } = this.state;
    let { isSupplierModal, isMintingModal, isBurnModal } = this.props;

    const columns = [
      {
        id: 'email',
        Header: 'Email',
        accessor: pendingTEX => pendingTEX['userId'] ? pendingTEX['userId']['email'] : '-',
      }, {
        id: 'amount',
        Header: 'Amount',
        accessor: pendingTEX => pendingTEX['amount'] ? pendingTEX['amount'] : '-',
      }, {
        id: 'status',
        Header: 'Status',
        accessor: pendingTEX => pendingTEX['status'] ? <p className="text-capitalize">{pendingTEX['status']}</p> : '-',
      }, {
        id: 'action',
        Header: 'Action',
        accessor: pendingTEX => <Fragment>
          {pendingTEX['status'] == "processing" &&
            <Fragment>
              {supplierAddress && userAddress.toLowerCase() == supplierAddress.toLowerCase()
                ? <Button variant="outlined" className="view-btn" onClick={() => this.showApproveModal(pendingTEX['amount'], pendingTEX['_id'])}>Approve</Button>
                : 'Supplier can approve this Transaction'
              }
            </Fragment>
          }
        </Fragment>,
        filterable: false,
      },
    ];

    return (
      <div className='content'>
        <div className="main-container supplier">
          <Slider />
          <Fragment>
            <div className='main-container-head mb-3'>
              <p className="main-container-heading">Supplier Address:
                <span className='protector-address'> {supplierAddress} </span>
              </p>
              <div>
                {supplierAddress && userAddress.toLowerCase() == supplierAddress.toLowerCase() &&
                  <Fragment>
                    <Button variant="outlined" className="add-btn mr-2" onClick={() => this.props.toggleMintingModal(true)}>Mint Tokens</Button>
                    <Button variant="outlined" className="add-btn mr-2" onClick={this.props.toggleBurnModal}>Burn Tokens</Button>
                    <Button variant="outlined" className="add-btn mr-2" onClick={this.props.toggleHistorySlider}>History</Button>
                  </Fragment>
                }
                {userAddress && (userAddress.toLowerCase() == ownerAddress.toLowerCase() || userAddress.toLowerCase() == supplierAddress.toLowerCase()) &&
                  <Button variant="outlined" className="add-btn" onClick={this.props.toggleSupplierModal}>Change Supplier</Button>
                }
              </div>
            </div>
            <ReactTable
              className="table"
              data={pendingTEX}
              resolveData={pendingTEX => pendingTEX.map(row => row)}
              columns={columns}
              minRows={20}
              filterable={true}
            />
          </Fragment>
        </div>

        {/* ---------------SUPPLIER MODAL--------------- */}
        <Modal isOpen={isSupplierModal} toggle={this.props.toggleSupplierModal} className="main-modal burn-modal">
          <ModalHeader toggle={this.props.toggleSupplierModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="burn-modal-title"><p>Add Supplier</p></div>
            <div className="burn-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.handleAddSupplier}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='address'
                    value={address}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>New Supplier Address <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isSupplier
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ------REACT_APP_PRIVATE_KEY---------MINTING MODAL--------------- */}
        <Modal isOpen={isMintingModal} toggle={() => this.props.toggleMintingModal(false)} className="main-modal minting-modal">
          <ModalHeader toggle={() => this.props.toggleMintingModal(false)}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="minting-modal-title"><p>Mint Tokens</p></div>
            <hr />
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.handleMintTokens}>
              <div className="row mb-3">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='mintAddress'
                    value={mintAddress}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>Minter Address <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row mb-3">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='mintAmount'
                    value={mintAmount}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>No. of TEX tokens <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row">
                <div className="col-12 pt-4 minting-btn-div">
                  <Button onClick={this.handleMintTokens} variant="contained" className="minting-btn">
                    {isMinting
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------BURN MODAL--------------- */}
        <Modal isOpen={isBurnModal} toggle={this.props.toggleBurnModal} className="main-modal burn-modal">
          <ModalHeader toggle={this.props.toggleBurnModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/icon.png')} alt="modal-logo" />
            </div>
            <div className="burn-modal-title"><p>Burn TEX Tokens</p></div>
            <div className="burn-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.burnTokensFunc}>
              <div className="row">
                <div className="col-2"></div>
                <div className='col-8'>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='burnAmount'
                    value={burnAmount}
                    id='standard-full-width'
                    className='text-field'
                    onChange={this.handleEditChange}
                    label={<label>No. of TEX tokens <span>*</span></label>}
                  />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 burn-btn-div">
                  <Button type="submit" variant="contained" className="burn-btn">
                    {isBurning
                      ? <div className='col-12 text-center blue-loader text-white'>
                        <i className='fa fa-spinner fa-spin fa-1x fa-fw' />
                      </div>
                      : 'Submit'
                    }
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------APPROVE MODAL--------------- */}
        <Fragment>
          <Modal isOpen={isApprovedModal} toggle={this.hideApproveModal} className="pause-modal approve-modal">
            <ModalHeader toggle={this.hideApproveModal}>
              <p className="modal-title text-dark">Approve Supply</p>
            </ModalHeader>
            <ModalBody>
              {isApproved
                ? <p className="text-dark">Are you sure you want to Supply TEX to this User</p>
                : <div className="my-4">
                  <AppLoader message="Please Wait...." />
                </div>
              }
            </ModalBody>
            <ModalFooter>
              {isApproved &&
                <Fragment>
                  <Button className="cancel-btn col-3" onClick={this.hideApproveModal}>Cancel</Button>
                  <Button className="confirm-btn col-3" onClick={this.handleApprove}>Confirm</Button>
                </Fragment>
              }
            </ModalFooter>
          </Modal>
        </Fragment>

      </div >
    );
  }
}

const mapDispatchToProps = {
  setPageLoader, getSupplierHistory, toggleSupplierModal, addSupplier, setAddress, setBalance,
  mintTokens, toggleMintingModal, toggleBurnModal, burnTokens, toggleHistorySlider,
};

const mapStateToProps = ({ Auth }) => {
  let { role, isSupplierModal, isMintingModal, isBurnModal } = Auth;
  return { role, isSupplierModal, isMintingModal, isBurnModal };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplyController);